import dynamic from "next/dynamic"
import NavBar from './NavBar'
import * as gtm from '../lib/gtm'
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { useRouter } from "next/router";
const Footer = dynamic(() => import('./Footer').then((module) => module.default),
{ssr: false} );


import { PhoneIcon, WhatsApp } from "./Icons";


const Layout = ({ children, header}) => {

  const [whats, setWhats] = useState(false);
  const [msj, setMsj] = useState('');
  const handleClose = () => setWhats(false);
  const handleShow = () => {
    setMsj('');
    setWhats(true);
  }
  const handleLead = () => {
    setWhats(false);
    if(msj == ''){
      if(router.locale == 'es'){
        setMsj('Hola, vi su página web y me gustaría más información de...')
      }else{
        setMsj('Hello, I saw your website and I would like more information about...')
      }
    }
    
    try{
      gtm.lead()
      
    }catch(e){
      console.log(e)
    }
  }
  const router = useRouter();
  return (
    <>
      <NavBar data={header} />
      {children}
      <a href="tel:+528120401551">
        <div className="PhoneIcon">
          <PhoneIcon/>
        </div>
      </a>
      <a  onClick={handleShow} id="whatsappTarget">
        <div className="WhatsappIcon">
          <WhatsApp/>
        </div>
      </a>

      <Modal show={whats} onHide={handleClose}  size="small" className="modal-whats">
        <Modal.Header><strong>{router.locale === "es" ? "Envíanos un mensaje para darte más información" : "Chat for more info"}</strong></Modal.Header>
        <Modal.Body>
          <div className="modal-whats-wrapper"> 
            <textarea placeholder={router.locale === "es" ? "Hola, vi su página web y me gustaría más información de..." :"Hello, I saw your website and I would like more information about..."} className="form-control" rows={4} onChange={(e)=>{setMsj(e.target.value)}}>{msj}</textarea>
            <a className="btn btn-whats " onClick={handleLead} on href={`https://wa.me/528120401551/?text=${msj}`} target='_blank'>{router.locale === "es" ? "Enviar" : "Send"}</a>
          </div>
        </Modal.Body>             
    </Modal>
      
      
      <Footer  />
    </>
  );
};

export default Layout;
